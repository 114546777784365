<template>
    <div class="font-stolzl">
        <div class="min-h-screen mb-10">

            <!-- SPACE-->
            <div id="space" class="md:h-16"></div>
            <!-- SPACE-->


            <!-- HEADING-->
            <div  class="w-auto h-auto overflow-hidden">
              <div :ref="setRef" data-anim="0" class="break-words font-light text-coolGray-900 mt-5 md:mt-28 mb-5 text-5xl px-4 md:px-8 opacity-0">Impressum</div>
            </div>
            <!-- HEADING-->

            <!-- CONTENT-->
            <pre class="font-stolzl w-full md:w-3/4 px-4 md:px-8 font-light text-base mt-5 text-coolGray-900 whitespace-pre-line">
            Angaben gem. §5 TMG
            rewhite-climbing GmbH
            Kronsaalsweg 70-74
            Eingang C
            22525, Hamburg

            Geschäftsführer
            David Laudert

            Registergericht :
            Amtsgericht Hamburg
            HRB143229

            Umsatzsteuer-ID
            DE308701138

            Kontakt
            E-Mail:hello@rewhite-climbing.de
            Verantwortlich nach §55 Abs.2 RStV : David Laudert

            Die Europäische Kommission hat eine Plattform zur Online-Streitbeilegung für den Abschluss von Online-KaufoderDienstleistungsverträgen bereitgestellt. Diese finden Sie unter folgendem Link: http://ec.europa.eu/consumers/odr/.

            Wir sind zur Teilnahme an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nicht verpflichtetund bereit.
            </pre>
            <!-- CONTENT-->

        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '../components/footer.vue';
import scrollToTop from '../mixins/scrollToTop.vue';
import intersectionObserver from '../mixins/intersectionObserver.vue';

export default {
  name: 'Imprint',
  components: { Footer },
  mixins: [ scrollToTop, intersectionObserver ],
}
</script>
